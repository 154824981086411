import { configureStore } from '@reduxjs/toolkit'

import userReducer from './reducer/user'
import authReducer from './reducer/auth'
import routerReducer from './reducer/router'
import dynamicDataReducer from './reducer/dynamicData'
import systemReducer from './reducer/system'

export default configureStore({
    reducer: {
        user:userReducer, //用户数据
        auth:authReducer, //授权数据
        router:routerReducer,//路由数据
        dynamicData:dynamicDataReducer,//动态数据
        system:systemReducer //系统参数数据
    }
})