
import { f7 } from 'framework7-react';

// export const message = (url, params) => {

// }
// export const notification = (url, params) => {
        
// }
// export const modal = (url, params) => {
        
// }

class modal {
    static info = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                title:"提示",
                content:args
            };
        }else{
            args = {
                ...{
                    title:"提示",
                    content:""
                },
                ...args
            };
        }

        f7.dialog.alert(args.content,args.title);
    }
    static success = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                title:"提示",
                content:args
            };
        }else{
            args = {
                ...{
                    title:"提示",
                    content:""
                },
                ...args
            };
        }

        f7.dialog.alert(args.content,args.title);
    }
    static error = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                title:"提示",
                content:args
            };
        }else{
            args = {
                ...{
                    title:"提示",
                    content:""
                },
                ...args
            };
        }

        f7.dialog.alert(args.content,args.title);
    }
    static warning = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                title:"提示",
                content:args
            };
        }else{
            args = {
                ...{
                    title:"提示",
                    content:""
                },
                ...args
            };
        }

        f7.dialog.alert(args.content,args.title);
    }
    static confirm = (args = {}) =>{

        args = Object.assign({
            title: '',
            content:"",
            destroyOnClose:true, //关闭时销毁
            backdrop:true,//是否显示遮罩背景
            closeByBackdropClick:false,//点击背景可关闭
            on: {
              open:()=>{}, //在打开动画开始前
              opened:()=>{}, //在打开动画开始后
              close:()=>{}, //在关闭动画开始前
              closed:()=>{}, //在关闭动画开始后
              beforeDestroy:()=>{}, //在销毁之前
              dialogBeforeDestroy:()=>{}, //在销毁之后
            },
            buttons:[]
        },args);

        f7.dialog.create(args).open();
    }
}

export default modal;

