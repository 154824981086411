import { createSlice } from '@reduxjs/toolkit'

export const systemReducer = createSlice({
    name: 'system',
    initialState: {
        
        unit:{} //绑定的组织机构数据
        
    },
    reducers: {
        //设置参数
        setParameter: (state,data) => {
        

        },
        //设置绑定的组织机构数据
        setUnit: (state,data) => {
            state.unit = data.payload
        },
        //清除绑定的组织机构数据
        clearUnit: (state,data) => {
            state.unit = {}
        }
    },
})

export const { setParameter,setUnit,clearUnit } = systemReducer.actions /*暴露reducers内的函数*/
export default systemReducer.reducer
