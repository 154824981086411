

import { f7 } from 'framework7-react';


class message {
    static open = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                content:args,
                closeTimeout:3000
            };
        }else{
            args = {
                ...{
                    content:"",
                    closeTimeout:3000
                },
                ...args
            };
        }
        const m = f7.toast.create({
            text: args.content,
            position: 'center',
            closeTimeout: args.closeTimeout,
            destroyOnClose:true //关闭时销毁 toast 实例
        });
        m.on("closed",()=>{
            m.$el.remove()
        });
        m.open();

    }
    static info = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                content:args,
                closeTimeout:3000
            };
        }else{
            args = {
                ...{
                    content:"",
                    closeTimeout:3000
                },
                ...args
            };
        }
        const m = f7.toast.create({
            text: args.content,
            position: 'center',
            closeTimeout: args.closeTimeout,
            destroyOnClose:true //关闭时销毁 toast 实例
        });
        m.on("closed",()=>{
            m.$el.remove()
        });
        m.open();
    }
    static success = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                content:args,
                closeTimeout:3000
            };
        }else{
            args = {
                ...{
                    content:"",
                    closeTimeout:3000
                },
                ...args
            };
        }
        const m = f7.toast.create({
            text: args.content,
            position: 'center',
            closeTimeout: args.closeTimeout,
            destroyOnClose:true //关闭时销毁 toast 实例
        });
        m.on("closed",()=>{
            m.$el.remove()
        });
        m.open();
    }
    static error = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                content:args,
                closeTimeout:3000
            };
        }else{
            args = {
                ...{
                    content:"",
                    closeTimeout:3000
                },
                ...args
            };
        }
        
        const m = f7.toast.create({
            text: args.content,
            position: 'center',
            closeTimeout: args.closeTimeout,
            destroyOnClose:true //关闭时销毁 toast 实例
        });
        m.on("closed",()=>{
            console.log(m.$el);
            m.$el.remove()
        });
        m.open();
    }
    static warning = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                content:args,
                closeTimeout:3000
            };
        }else{
            args = {
                ...{
                    content:"",
                    closeTimeout:3000
                },
                ...args
            };
        }
        const m = f7.toast.create({
            text: args.content,
            position: 'center',
            closeTimeout: args.closeTimeout,
            destroyOnClose:true //关闭时销毁 toast 实例
        });
        m.on("closed",()=>{
            m.$el.remove()
        });
        m.open();
    }
    static warn = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                content:args,
                closeTimeout:3000
            };
        }else{
            args = {
                ...{
                    content:"",
                    closeTimeout:3000
                },
                ...args
            };
        }
        const m = f7.toast.create({
            text: args.content,
            position: 'center',
            closeTimeout: args.closeTimeout,
            destroyOnClose:true //关闭时销毁 toast 实例
        });
        m.on("closed",()=>{
            m.$el.remove()
        });
        m.open();
    }

    static modal_error = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                content:args,
                closeTimeout:3000
            };
        }else{
            args = {
                ...{
                    content:"",
                    closeTimeout:3000
                },
                ...args
            };
        }
       
        f7.dialog.alert(args.content);
    }


}

export default message;

