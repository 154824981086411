const configuration = {
    request:{
        hosts:null
    },
    base:{},
    resources:{},

    type:"scape", //系统开发类型 edu（教育）、police（警务）、enterprise（企业）、org(政务) insdep、exam考试系统、ids
    
    govaws:{

        name:"黄家埠镇企业安全生产监管系统",
        description:"",
        copyright:"",

        request:{
            hosts:(process.env.NODE_ENV === 'development' ? "http://10.0.0.2:4001/application" : 'http://hjb.yuyao.angovs.com/api/application')
        },


        api:{
            baidu:{
                ak:"EGZv1wnhSsSeKTDjNBCRpuK3VuqfyqSc"
            }
        },
        platform:"auto", //系统平台 app、wechat、dingchat、auto或default自动
        system:{


            object:"company_user",

            uuid:"80e481e6-b73f-dbd0-e4c3-b4e77acdd4b8",

            version:"1.0.0", //主版本号
            versionNumber:"1000", //应用版本号
            versionSubNumber:"1000", //应用子版本号
            lastTime:"2024年05月12日",
        },

        app:{
            name: 'govaws', /**应用名称*/
            theme: 'ios',   /**应用主题,可以是ios、md或auto。在auto的情况下，它将为iOS设备使用iOS主题，为所有其他设备使用MD主题。*/
            picker:{
                toolbarCloseText:"完成"
            },
            dialog: {
                buttonOk: '确认',
                buttonCancel: '取消'
            },
        },

        view:{ /*APP视图配置*/
            //name:"",    /**如果有视图名称，则可以通过 app.views.[name] 访问它*/
            //main:true,  /**指定这是否是主要视图。 如果不通过则根据其元素ClassName是否有view-main类来判断*/

            url:"/", /** 默认（初始）视图的url。如果不指定，则等于文档url */

            browserHistory:false, /** browserHistory 说明：
                                    * 如果开发的是移动APP应用那么为false，反之如果为移动web那么为true。
                                    * 主要区别在于true时，启动哈希导航，例如“http://my-webapp.com/#!/about.html”，使能够使用浏览器的默认后退和前进按钮浏览应用程序的历史记录。 
                                    **/

            browserHistoryInitialMatch:true, /** browserHistoryInitialMatch 说明：
                                                * 默认为false（禁用），一般为移动web开发时启用，此属性表示，如果用户直接浏览器输入指定路由访问时，直接初始化指定的路由页面。
                                                * React开发中必须为 true
                                                **/ 
            browserHistoryRoot:"pages",
            /**
             * browserHistoryRoot 与 browserHistorySeparator 仅可选其一
             * browserHistoryRoot 为 "" 时，则browserHistorySeparator有效，反之browserHistorySeparator无效
             * 
             * 如果当设置 browserHistoryRoot:"pages"时，则访问地址变成 http://my-webapp.com/pages/example 或 http://my-webapp.com/index.html?/pages/example
             * 访问根目录时 http://my-webapp.com/ 或 http://my-webapp.com/index.html?/pages
             * 
             * 如果访问地址有带参数时，则访问地址变成 http://my-webapp.com/pages/example?id=123 或 http://my-webapp.com/index.html?/pages/example?id=123
             * 注意：如果访问地址有带参数需求，一般不建议使用browserHistoryRoot，防止浏览器判断错误
            */
            browserHistorySeparator:"#",  
            /** 
             * browserHistorySeparator 说明：
            * 默认为“#!”，哈希导航匹配的分割符，默认效果如：“http://my-webapp.com/#!/about.html”，修改为 “#page/” 则显示导航为 “http://myapp.com/#page/about.html”
            **/ 


            /**
             * 注意事项及常见问题：
             * 1、framework7 build后的站点文件不能放在二级目录下，会导致"/"根路由无法解析。由于f7没有提供类似 BrowserRouter basename="/" 的方法，因此暂无好的解决方案。
            */
        },
        
        /**
         * router 注意事项：
         * 1、首页必须有 keepAlive:true 属性，否则在三级路由返回时会出错。
        */
        router:{
   
            lists:[
                {path:"/",element:"govaws/index",keepAlive:true},
                {
                    path:"/setting/",element:"govaws/setting",
                    children:[
                        {path:"account",element:"govaws/setting/account"},
                        {path:"about",element:"govaws/setting/about"},
                        {path:"password",element:"govaws/setting/password"},
                        {path:"application",element:"govaws/setting/application"},
                    ]
                },
                
                {path:"/initiate",element:"govaws/initiate"},
                {path:"/todo",element:"govaws/todo"},
                {path:"/receive",element:"govaws/receive"},

                {path:"/detail/:id",element:"govaws/detail"},

                {path:"/application/company/:type",element:"govaws/application/company/lists"},
                {path:"/application/company_operate/:company_id/:type",element:"govaws/application/company/operate"},

                {path:"/application/dangerous",element:"govaws/application/dangerous/lists"},
                {path:"/application/dangerous_operate/:dangerous_id",element:"govaws/application/dangerous/operate"},

                {path:"/application/inspection",element:"govaws/application/inspection/lists"},
                {path:"/application/inspection_operate/:inspection_id",element:"govaws/application/inspection/operate"},
                {path:"/application/inspection_detail/:inspection_id",element:"govaws/application/inspection/detail"},
                

                {path:"/application/company_user",element:"govaws/application/user/lists"},
                {path:"/application/company_user_operate/:company_user_id",element:"govaws/application/user/operate"},

                {path:"/application/report/:type_id",element:"govaws/application/report"},

                {path:"/announcement/lists/:category_id",element:"govaws/announcement/lists"},
                {path:"/announcement/detail/:category_id/:content_id",element:"govaws/announcement/detail"},
             
                {path:"(.*)",element:"error"}
            ]
        },
        
    },


    scape:{

        name:"醉美四明山",
        description:"",
        copyright:"",

        request:{
            hosts:(process.env.NODE_ENV === 'development' ? "http://10.0.0.2:4001/application" : 'http://wechat.dswitch.net/api/application')
        },


        api:{
            baidu:{
                ak:"EGZv1wnhSsSeKTDjNBCRpuK3VuqfyqSc"
            }
        },
        platform:"auto", //系统平台 app、wechat、dingchat、auto或default自动
        system:{

            uuid:"504fe6ce-d949-303e-780b-b8d52535da81",

            version:"1.0.0", //主版本号
            versionNumber:"1000", //应用版本号
            versionSubNumber:"1000", //应用子版本号
            lastTime:"2024年03月05日",
        },

        app:{
            name: 'scape', /**应用名称*/
            theme: 'ios',   /**应用主题,可以是ios、md或auto。在auto的情况下，它将为iOS设备使用iOS主题，为所有其他设备使用MD主题。*/
            picker:{
                toolbarCloseText:"完成"
            },
            dialog: {
                buttonOk: '确认',
                buttonCancel: '取消'
            },
        },

        view:{ /*APP视图配置*/
            //name:"",    /**如果有视图名称，则可以通过 app.views.[name] 访问它*/
            //main:true,  /**指定这是否是主要视图。 如果不通过则根据其元素ClassName是否有view-main类来判断*/

            url:"/", /** 默认（初始）视图的url。如果不指定，则等于文档url */

            browserHistory:true, /** browserHistory 说明：
                                    * 如果开发的是移动APP应用那么为false，反之如果为移动web那么为true。
                                    * 主要区别在于true时，启动哈希导航，例如“http://my-webapp.com/#!/about.html”，使能够使用浏览器的默认后退和前进按钮浏览应用程序的历史记录。 
                                    **/

            browserHistoryInitialMatch:true, /** browserHistoryInitialMatch 说明：
                                                * 默认为false（禁用），一般为移动web开发时启用，此属性表示，如果用户直接浏览器输入指定路由访问时，直接初始化指定的路由页面。
                                                **/ 
            browserHistoryRoot:"pages",
            /**
             * browserHistoryRoot 与 browserHistorySeparator 仅可选其一
             * browserHistoryRoot 为 "" 时，则browserHistorySeparator有效，反之browserHistorySeparator无效
             * 
             * 如果当设置 browserHistoryRoot:"pages"时，则访问地址变成 http://my-webapp.com/pages/example 或 http://my-webapp.com/index.html?/pages/example
             * 访问根目录时 http://my-webapp.com/ 或 http://my-webapp.com/index.html?/pages
             * 
             * 如果访问地址有带参数时，则访问地址变成 http://my-webapp.com/pages/example?id=123 或 http://my-webapp.com/index.html?/pages/example?id=123
             * 注意：如果访问地址有带参数需求，一般不建议使用browserHistoryRoot，防止浏览器判断错误
            */

            browserHistorySeparator:"#",  
            /** 
             * browserHistorySeparator 说明：
            * 默认为“#!”，哈希导航匹配的分割符，默认效果如：“http://my-webapp.com/#!/about.html”，修改为 “#page/” 则显示导航为 “http://myapp.com/#page/about.html”
            **/ 


            /**
             * 注意事项及常见问题：
             * 1、framework7 build后的站点文件不能放在二级目录下，会导致"/"根路由无法解析。由于f7没有提供类似 BrowserRouter basename="/" 的方法，因此暂无好的解决方案。
            */
        },
        
         /**
         * router 注意事项：
         * 1、首页必须有 keepAlive:true 属性，否则在三级路由返回时会出错。
        */
        router:{
            lists:[
                {path:"/",element:"scape/index",keepAlive:true},
                {path:"/map",element:"scape/map"},
                {path:"/map/:type",element:"scape/map"},
                {path:"/live",element:"scape/live"},
                {path:"(.*)",element:"error"}
            ]
        },
        
    },

    wms:{

        name:"Insdep WMS",
        description:"",
        copyright:"",

        request:{
            hosts:(process.env.NODE_ENV === 'development' ? "http://10.0.0.2:4001/application" : 'http://wms.anedus.com/api/application')
        },


        api:{
            baidu:{
                ak:"EGZv1wnhSsSeKTDjNBCRpuK3VuqfyqSc"
            }
        },
        platform:"auto", //系统平台 app、wechat、dingchat、auto或default自动
        system:{

            object:"school",

            uuid:"504fe6ce-d949-303e-780b-b8d52535da81",

            version:"1.0.0", //主版本号
            versionNumber:"1000", //应用版本号
            versionSubNumber:"1000", //应用子版本号
            lastTime:"2024年03月05日",
        },

        app:{
            name: 'wms', /**应用名称*/
            theme: 'ios',   /**应用主题,可以是ios、md或auto。在auto的情况下，它将为iOS设备使用iOS主题，为所有其他设备使用MD主题。*/
            picker:{
                toolbarCloseText:"完成"
            },
            dialog: {
                buttonOk: '确认',
                buttonCancel: '取消'
            },
        },

        view:{ /*APP视图配置*/
            //name:"",    /**如果有视图名称，则可以通过 app.views.[name] 访问它*/
            //main:true,  /**指定这是否是主要视图。 如果不通过则根据其元素ClassName是否有view-main类来判断*/

            url:"/", /** 默认（初始）视图的url。如果不指定，则等于文档url */

            browserHistory:false, /** browserHistory 说明：
                                    * 如果开发的是移动APP应用那么为false，反之如果为移动web那么为true。
                                    * 主要区别在于true时，启动哈希导航，例如“http://my-webapp.com/#!/about.html”，使能够使用浏览器的默认后退和前进按钮浏览应用程序的历史记录。 
                                    **/

            browserHistoryInitialMatch:true, /** browserHistoryInitialMatch 说明：
                                                * 默认为false（禁用），一般为移动web开发时启用，此属性表示，如果用户直接浏览器输入指定路由访问时，直接初始化指定的路由页面。
                                                * React开发中必须为 true
                                                **/ 
            browserHistoryRoot:"pages",
            /**
             * browserHistoryRoot 与 browserHistorySeparator 仅可选其一
             * browserHistoryRoot 为 "" 时，则browserHistorySeparator有效，反之browserHistorySeparator无效
             * 
             * 如果当设置 browserHistoryRoot:"pages"时，则访问地址变成 http://my-webapp.com/pages/example 或 http://my-webapp.com/index.html?/pages/example
             * 访问根目录时 http://my-webapp.com/ 或 http://my-webapp.com/index.html?/pages
             * 
             * 如果访问地址有带参数时，则访问地址变成 http://my-webapp.com/pages/example?id=123 或 http://my-webapp.com/index.html?/pages/example?id=123
             * 注意：如果访问地址有带参数需求，一般不建议使用browserHistoryRoot，防止浏览器判断错误
            */
            browserHistorySeparator:"#",  
            /** 
             * browserHistorySeparator 说明：
            * 默认为“#!”，哈希导航匹配的分割符，默认效果如：“http://my-webapp.com/#!/about.html”，修改为 “#page/” 则显示导航为 “http://myapp.com/#page/about.html”
            **/ 


            /**
             * 注意事项及常见问题：
             * 1、framework7 build后的站点文件不能放在二级目录下，会导致"/"根路由无法解析。由于f7没有提供类似 BrowserRouter basename="/" 的方法，因此暂无好的解决方案。
            */
        },
        
        /**
         * router 注意事项：
         * 1、首页必须有 keepAlive:true 属性，否则在三级路由返回时会出错。
        */
        router:{
   
            lists:[
                {path:"/",element:"warehouse/index",keepAlive:true},
                {path:"/app/",element:"warehouse/index",keepAlive:true}, //用于解决二级目录下的默认首页，与craco.config.js中定义的目录一直
                {
                    path:"/setting/",element:"warehouse/setting",
                    children:[
                        {path:"account",element:"warehouse/setting/account"},
                        {path:"about",element:"warehouse/setting/about"},
                        {path:"password",element:"warehouse/setting/password"},
                        {path:"application",element:"warehouse/setting/application"},
                    ]
                },
                
                {path:"/initiate",element:"warehouse/initiate"},
                {path:"/todo",element:"warehouse/todo"},
                {path:"/receive",element:"warehouse/receive"},

                {path:"/detail/:id",element:"warehouse/detail"},

                {path:"/application/receive",element:"warehouse/application/receive"},

                {path:"/announcement/lists/:category_id",element:"warehouse/announcement/lists"},
                {path:"/announcement/detail/:category_id/:content_id",element:"warehouse/announcement/detail"},
             
                {path:"(.*)",element:"error"}
            ]
        },
        
    },

    
    
};
export default configuration;