import React, { useEffect } from 'react';
import {
    App,
    View
} from 'framework7-react';

const Default = () => {
    const projectData = React.insdep.project;

    function buildRoutes(lists) {  
        let routes = [];  
        lists.forEach(route => {  
            let node = {  
                path: route.path,
                asyncComponent : () => import('@pages/' + route.element)
            };  
      
            if ('keepAlive' in route) {  
                node.keepAlive = route.keepAlive;  
            }  
      
            if ('master' in route) {  
                node.master = route.master;  
            }  

            if (route.children) {  
                // 递归地为子路由构建配置  
                node.routes = buildRoutes(route.children);  
                // node.routes.forEach(childNode => {  
                //     // 更新子路由的路径，添加父路由的路径前缀  
                //     //childNode.path = route.path + '/' + childNode.path;  
                // });  
            }  
      
            routes.push(node);  
        });  
      
        return routes;  
    }  

    let routes = buildRoutes(projectData.router.lists);
    //console.log(routes);
    
    const appParameter = { 
        ...projectData.app, 
        ...{
            routes: routes
        }
    };

    const viewParameter = { 
        ...projectData.view, 
        ...{
            main:true, /*是否为主视图*/
            onViewInit:()=>{},
            onViewResize:()=>{},
        }
    };    

    useEffect(()=>{
		//加载成功后
       

        return ()=>{
            //卸载后
        }
    },[]); //eslint-disable-line

    
    return  <React.Fragment>
                <App { ...appParameter }>
                    <View { ...viewParameter }/>
                </App>
            </React.Fragment>
            
}
export default Default;