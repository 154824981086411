import { createSlice } from '@reduxjs/toolkit'

export const authReducer = createSlice({
    name: 'auth',
    initialState: {
        state:false, //授权状态
        
    },
    reducers: {
        setAuth: (init,data) => {
            if(data.payload.hasOwnProperty('state')){
                init.state = data.payload.state;
            }
            
        }
    },
})

export const { setAuth } = authReducer.actions /*暴露reducers内的函数*/
export default authReducer.reducer
