import { createSlice } from '@reduxjs/toolkit'

export const dynamicDataReducer = createSlice({
    name: 'dynamicData',
    initialState: {
        handle:0,
        copy:0
        
    },
    reducers: {
        setDynamicData: (state,data) => {
            

        }
    },
})

export const { setDynamicData } = dynamicDataReducer.actions /*暴露reducers内的函数*/
export default dynamicDataReducer.reducer
